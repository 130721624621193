exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-expertise-tsx": () => import("./../../../src/templates/expertise.tsx" /* webpackChunkName: "component---src-templates-expertise-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-people-tsx": () => import("./../../../src/templates/people.tsx" /* webpackChunkName: "component---src-templates-people-tsx" */),
  "component---src-templates-person-tsx": () => import("./../../../src/templates/person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */)
}

